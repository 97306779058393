










































import { DataTableHeader } from "vuetify";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import nameof from "@/utility/nameof";
import Api from "@/api";
import { AgencyDocumentModel } from "@/api/generated";
import UserModule from "@/store/userModule";
import Discriminator from '@/types/Discriminator';

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class AgencyDocumentTable extends Vue {
  @Prop(String) private agencyId!: string;
  
  private loading = false;
  private documents: Array<AgencyDocumentModel> = [];
  private headers: Array<DataTableHeader<AgencyDocumentModel>> = [
    { text: "", value: "actions" },
    { text: "Filename", value: nameof<AgencyDocumentModel>("filename") },
    {
      text: "Reference",
      value: nameof<AgencyDocumentModel>("reference")
    },
    {
      text: "Uploaded",
      value: nameof<AgencyDocumentModel>("createdUtc")
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getDocuments();
  }

  @Watch("agencyId", { immediate: true })
  private async getDocuments() {
    try {
      this.loading = true;
      const response = await Api.AgencyDocumentService.apiAgencydocumentAgencyAgencyIdGet(
        this.agencyId
      );
      this.documents = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch documents");
    } finally {
      this.loading = false;
    }
  }
}
