

























import { AgencyDocumentModel } from "@/api/generated";
import { Vue, Component, Prop } from "vue-property-decorator";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import AgencyDocumentTable from "@/components/agency/AgencyDocumentTable.vue";
import AgencyDocumentCreateDialog from "@/components/dialogs/AgencyDocumentCreateDialog.vue";
import AgencyDocumentUpdateDialog from "@/components/dialogs/AgencyDocumentUpdateDialog.vue";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    AgencyDocumentTable,
    AgencyDocumentCreateDialog,
    AgencyDocumentUpdateDialog
  }
})
export default class AgencyDocuments extends Vue {
  @Prop(String) private agencyId!: string;
  private selectedAgencyDocumentId: string | undefined = "";
  private refreshKey = false;
  private createDialog = false;
  private updateDialog = false;

  private getDocuments() {
    this.refreshKey = !this.refreshKey;
  }

  private onCreateDocument() {
    this.createDialog = true;
  }

  private onEditDocument(document: AgencyDocumentModel) {
    if (!document.agencyId) {
      return;
    }

    this.selectedAgencyDocumentId = document.agencyDocumentId;
    this.updateDialog = true;
  }

  private async onDownloadDocument(document: AgencyDocumentModel) {
    if (!document.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      document.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, document.filename);
  }

  private async onDeleteDocument(document: AgencyDocumentModel) {
    if (!document.agencyDocumentId) {
      return;
    }

    try {
      await Api.AgencyDocumentService.apiAgencydocumentAgencyDocumentIdDelete(
        document.agencyDocumentId
      );
      snackbarModule.setSnackbarMessage("Document deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete document");
    } finally {
      this.refreshKey = !this.refreshKey;
    }
  }
}
